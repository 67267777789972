<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">职工列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{ title }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist" style="width:70%">
              <div>
                <h3>
                  <span>基本信息</span>
                </h3>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="职工姓名" prop="userName">
                      <el-input v-model="baseform.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idcard">
                      <el-input :disabled="disabled" v-model="baseform.idcard" @input="(val)=>{getIdcard(val)}"></el-input>
                      <div @click="showInfo" v-show="showtip" class="tip">{{ idcard || "" }}</div>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                      <el-radio-group :disabled="disabled" v-model="baseform.sex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                      <el-input :disabled="disabled" v-model="baseform.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮件" prop="email">
                      <el-input v-model="baseform.email"></el-input>
                    </el-form-item>
                    <el-form-item label="文化水平">
                      <el-select v-model="baseform.educationLevel" placeholder="请选择">
                        <el-option
                          v-for="item in education"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="专业">
                      <el-input v-model="baseform.profession"></el-input>
                    </el-form-item>
                    <el-form-item label="职称">
                      <el-input v-model="baseform.professionTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="部门">
                      <el-input v-model="baseform.department"></el-input>
                    </el-form-item>
                    <el-form-item label="工种">
                      <el-input v-model="baseform.workType"></el-input>
                    </el-form-item>
                    <el-form-item label="人员类型">
                      <el-select v-model="baseform.userType" placeholder="请选择">
                        <el-option
                          v-for="item in userType"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv">取 消</el-button>
                <el-button v-if="stu === 'add'" type="primary" class="bgc-bv" @click="doAddSave">确 定</el-button>
                <el-button v-else type="primary" class="bgc-bv" @click="doEditSave">确 定</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "addworkers",
  components: {},
  data() {
    return {
      readonly: false,
      nameReadonly:false,
      showtip: false,
      idcard: "",
      idcardObj: {},
      disabled: false,
      stu: "add",
      title: "新增职工",
      // 基本信息
      baseform: {
        mobile: "", // 手机号
        sex: "", // 性别
        email: "", // 邮件
        idcard: "", // 身份证
        userName: "", // 用户名
        educationLevel: "", // 文化水平
        profession: "", // 专业
        workType: "", // 工种
        userType: "", // 人员类型
        professionTitle: "", // 职称
        department: "" // 部门
      },
      rules: {
        userName: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        idcard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" }
        ],
        mobile: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur"
          }
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        email:[ { required: false, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }]
      },
      userId: "",
      education: [],
      userType: []
    };
  },
  created() {
    this.getEducation();
    this.getUserType();
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.stu = this.$route.query.stu;
      if (this.$route.query.stu == "add") {
        this.title = "新增职工";
      } else {
        this.title = "编辑职工";
        this.userId = this.$route.query.id;
        this.readonly = true;
        this.disabled = true;
        this.queryData();
      }
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.sex(newValue);
        this.$post("/biz/user/queryUserByIdCard", {
          idcard: this.baseform.idcard
        })
          .then(res => {
            if (res.data == null) {
              this.showtip = false;
            } else {
              this.idcardObj = res.data;
              this.idcard =
                res.data.userName +
                  " | " +
                  res.data.idcard +
                  " | " +
                  res.data.mobile || "";
              this.showtip = true;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.showtip = false;
        // this.$refs.baseform.resetFields();
      }
    },
    showInfo() {
      this.showtip = false;
      this.readonly = true;
      this.nameReadonly = true;
      this.baseform.userName = this.idcardObj.userName;
      this.baseform.idcard = this.idcardObj.idcard;
      this.baseform.mobile = this.idcardObj.mobile;
      this.baseform.sex = this.idcardObj.sex;
      this.$refs.baseform.clearValidate();
    },
    // 新增保存
    doAddSave() {
      const params = {
        mobile: this.baseform.mobile, // 手机号
        sex: this.baseform.sex, // 性别
        idcard: this.baseform.idcard, // 身份证
        userName: this.baseform.userName, // 用户名
        email: this.baseform.email || null, // 邮件
        educationLevel: this.baseform.educationLevel || null, // 文化水平
        profession: this.baseform.profession || null, // 专业
        workType: this.baseform.workType || null, // 工种
        userType: this.baseform.userType || null, // 人员类型
        professionTitle: this.baseform.professionTitle || null, // 职称
        department: this.baseform.department || null // 部门
      };
      const baseform = this.$refs["baseform"];
      baseform.validate(valid => {
        if (valid) {
          this.$post("/biz/user/insert", params)
            .then(res => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "新增成功"
                });
                this.$router.push("/web/workersList");
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.$message({
            type: "warning",
            message: "请检查必填项"
          });
        }
      });
    },
    // 文化水平
    getEducation() {
      this.$post("/sys/dictionary/key", {
        dictKey: "EDUCATION"
      })
        .then(res => {
          ;
          if (res.status == "0") {
            this.education = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 人员类型
    getUserType() {
      this.$post("/sys/dictionary/key", {
        dictKey: "USERTYPE"
      })
        .then(res => {
          ;
          if (res.status == "0") {
            this.userType = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 编辑保存
    doEditSave() {
      const params = {
        userId: this.userId,
        mobile: this.baseform.mobile, // 手机号
        sex: this.baseform.sex, // 性别
        idcard: this.baseform.idcard, // 身份证
        userName: this.baseform.userName, // 用户名
        email: this.baseform.email || null, // 邮件
        educationLevel: this.baseform.educationLevel || null, // 文化水平
        profession: this.baseform.profession || null, // 专业
        workType: this.baseform.workType || null, // 工种
        userType: this.baseform.userType || null, // 人员类型
        professionTitle: this.baseform.professionTitle || null, // 职称
        department: this.baseform.department || null // 部门
      };
      const baseform = this.$refs["baseform"];
      baseform.validate(valid => {
        if (valid) {
          this.$post("/biz/user/modifyUser", params)
            .then(res => {
              if (res.status == 0) {
                this.$router.push("/web/workersList");
              }
            })
            .catch(err => {
              return;
            });
        }
      });
    },
    // 编辑查询
    queryData() {
      this.$post("/biz/user/getUserInfo", { userId: this.userId })
        .then(res => {
          const data = res.data || {};
          this.baseform = {
            ...this.baseform,
            mobile: data.mobile, // 手机号
            sex: data.sex, // 性别
            email: data.email, // 邮件
            idcard: data.idcard, // 身份证
            userName: data.userName, // 用户名
            educationLevel: data.educationLevel, // 文化水平
            profession: data.profession, // 专业
            workType: data.workType, // 工种
            userType: data.userType, // 人员类型
            professionTitle: data.professionTitle, // 职称
            department: data.department // 部门
          };
        })
        .catch(err => {
          console.log(err);
        });
    },
    doCancel() {
      this.$router.push("/web/workersList");
    },
     sex(val) {
      if (parseInt(val.substr(16, 1)) % 2 == 1) {
        //男
        this.baseform.sex = "1";
      } else {
        //女
        this.baseform.sex = "2";
      }
    }

  }
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.addlist {
  h3 {
    display: flex;
    border-left: 3px solid rgb(92, 107, 232);
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 70%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
  button {
    padding: 12px 30px;
  }
}
.tip {
  position: relative;
  z-index: 100;
  // width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
</style>
